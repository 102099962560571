import React from 'react'
import AboutComp from '../components/AboutCom/AboutComp'
import Banner from '../components/Banner/Banner'
import CounterSection from '../components/CounterSection/CounterSection'
import ServicesSection from '../components/ServicesSection/ServicesSection'
import SkillSection from '../components/SkillSection/SkillSection'
import WhyChooseUs from '../components/WhyChooseUs/WhyChooseUs'

const Home = () => {
  return (
    <>
      <Banner/>
      <AboutComp/>
      <ServicesSection/>
      <CounterSection/>
      <WhyChooseUs/>
      <SkillSection/>
    </>
  )
}

export default Home
