import React from 'react'
import { Link } from 'react-router-dom'

const PageTitle = ({title}) => {
  return (
    <>
     <div className="page__banner">
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="page__banner-content">
						<h1>{title}</h1>
						<div className="page__banner-menu">
							<ul>
								<li><Link to="/"><i className="flaticon-home-icon-silhouette"></i> Home - </Link>
								</li>
								<li> {title}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 
    </>
  )
}

export default PageTitle
