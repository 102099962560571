import React, { useEffect } from 'react';
import TopBar from './components/TopBar/TopBar';
import NavBar from './components/NavBar/NavBar'
// import Header from './components/Header/Header';
// import all css
import './assets/css/animate.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/fontawesome.min.css'
import './assets/css/magnific-popup.css'
import './assets/css/meanmenu.min.css'
import './assets/css/nice-select.css'
import './assets/sass/index.css'
import './assets/css/swiper-bundle.min.css'
import './assets/fonts/flaticon.css'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './screens/Home';
import About from './screens/About';
import Services from './screens/Services';
import ContactUs from './screens/ContactUs';
import FooterComp from './components/FooterComp/FooterComp';
import ScrollToTop from './assets/js/ScrollToTop';
import CopyRight from './components/CopyRight/CopyRight';
import ServiceDetails from './screens/ServiceDetails';
import { useDispatch } from 'react-redux';
import { getServices } from './redux/actions/services';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <TopBar />
        <NavBar />
        <Routes>
          <Route index path='/' element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="services" element={<Services />}/>         
          <Route path="service/:name" element={<ServiceDetails/>}/>
          <Route path="contact-us" element={<ContactUs />} />
        </Routes>
        <FooterComp />
        <CopyRight/>
      </BrowserRouter>


    </>
  );
}

export default App;
