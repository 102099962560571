import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image'
import logo from '../../assets/img/logo.png'
import CustomOffcanvas from '../Offcanvas/CustomOffcanvas';
import { Link, NavLink } from 'react-router-dom';

function CollapsibleExample() {
    const styles = {
        logo: {
            width: "200px"
        }
    }

    const navelinkstyles=({ isActive }) => {
        return {
          display: "block",
          margin: "5px 20px",
          color: isActive ? "#c37328" : "#4d3426",
          fontWeight: "bold"
        };
      }


    return (
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light" className="shadow" sticky='top'>
            <Container>

                {/* <div className='header__area-topbar custom-container'> */}

                <Navbar.Brand>
                    <Link to="/"><Image fluid='false' src={logo} alt="logo" style={styles.logo} /></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                        {/* <Nav.Link href="#features">Home</Nav.Link>
                        <Nav.Link href="#pricing">About</Nav.Link>
                        <Nav.Link href="#pricing">Services</Nav.Link>
                        <Nav.Link href="#pricing">Contact Us</Nav.Link> */}
                        <NavLink style={navelinkstyles} to="/">Home</NavLink>
                        <NavLink style={navelinkstyles} to="about">About</NavLink>
                        <NavLink style={navelinkstyles} to="services">Services</NavLink>
                        <NavLink style={navelinkstyles} to="contact-us">Contact Us</NavLink>

                    </Nav>
                    <Nav>
                        <CustomOffcanvas/>
                    </Nav>
                </Navbar.Collapse>
                {/* </div> */}
            </Container>
        </Navbar>
    );
}

export default CollapsibleExample;