import React from 'react'
import phoneCallImage from '../../assets/img/icon/phone-call.png';
import emailImage from '../../assets/img/icon/email.png'

const ContactDetailCard = () => {
    return (
        <>
            <div className="contact__list section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-6 md-mb-30 wow fadeInUp" data-wow-delay=".8s">
                            <div className="contact__list-item">
                                <div className="contact__list-item-icon">
                                    <img src={phoneCallImage} alt="" />
                                </div>
                                <a href="tel:+923001567019">
                                    <p>+92-300-1567019</p>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="1.2s">
                            <div className="contact__list-item">
                                <div className="contact__list-item-icon">
                                    <img src={emailImage} alt="" />
                                </div>
                                <a href="mailto:info@contrivermate.com">
                                    <p>info@contrivermate.com</p>
                                </a>
                                <a href="mailto:support@contrivermate.com">
                                    <p>support@contrivermate.com</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactDetailCard
