import React from 'react'
import faqImage from '../../assets/img/faq.jpg'

const FaqArea = () => {
    return (
        <>
            <div className="faq__area-about section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 order-lg-last">
                            <div className="faq__area-left p-relative">
                                <h2 className="mb-50">Techniques for the fast Of information</h2>
                                <div className="faq__area-image">
                                    <img src={faqImage} alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-6">
                            <div className="faq__area-collapse">
                                <p className="section-top mb-20">FAQ</p>
                                <div className="faq__area-collapse-item">
                                    <div className="faq__area-collapse-item-card">
                                        <div className="faq__area-collapse-item-card-header">
                                            <h3>01. How does information technology work?</h3>
                                            <i className="flaticon-plus"></i>
                                        </div>
                                        <div className="faq__area-collapse-item-card-header-content" style={{ display: "none" }}>
                                            <p>Pellentesque ac accumsan massa, in accumsan enim. Vestibulum dui diam, aliquet eu quam vel, tempus egestas lorem. Maecenas quis ipsum libero.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="faq__area-collapse-item">
                                    <div className="faq__area-collapse-item-card">
                                        <div className="faq__area-collapse-item-card-header">
                                            <h3>02. How can I become IT manager?</h3>
                                            <i className="flaticon-minus"></i>
                                        </div>
                                        <div className="faq__area-collapse-item-card-header-content active">
                                            <p>Pellentesque ac accumsan massa, in accumsan enim. Vestibulum dui diam, aliquet eu quam vel, tempus egestas lorem. Maecenas quis ipsum libero.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="faq__area-collapse-item mb-0">
                                    <div className="faq__area-collapse-item-card">
                                        <div className="faq__area-collapse-item-card-header">
                                            <h3>03. What are the latest trends in IT?</h3>
                                            <i className="flaticon-plus"></i>
                                        </div>
                                        <div className="faq__area-collapse-item-card-header-content" style={{ display: "none" }}>
                                            <p>Pellentesque ac accumsan massa, in accumsan enim. Vestibulum dui diam, aliquet eu quam vel, tempus egestas lorem. Maecenas quis ipsum libero.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqArea
