import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png'

const FooterComp = () => {

	
    const { services } = useSelector(
        (state) => state.services
    );

    const service = services.map((val) => val.service_title)


  return (
    <>
     <div className="footer__area pt-100 pb-100">
		<div className="container">
			<div className="row">
				<div className="col-xl-4 col-lg-5 col-md-8 md-mb-30">
					<div className="footer__area-footer-widget">
						<div className="footer__area-logo">
							<a href="index.html">
								<img src={logo} alt="logo"/>
							</a>
						</div>
						<div className="footer__area-content">
							<p>We've been in business for over three years, and we're excited to see what the future holds for us. Our philosophy is simple: we want to make your website look great, sound great, and work exactly how you need it to. We are a team of professionals who have been providing IT services for over 3+ years. We pride ourselves on the quality of our work, and we never settle for anything less than excellence.</p>
						</div>
					</div>
				</div>
				<div className="col-xl-2 col-lg-3 fo-b col-md-4">
					<div className="footer__area-footer-widget">
						<div className="footer__area-title">
							<h3>Services</h3>
						</div>
						<div className="footer__area-footer-menu">
							<ul>
							{service.map((val, ind) => {
                            return <li key={ind}><Link to={`/service/${val.replace(/\s+/g, '-').toLowerCase()}`}>{val}</Link></li>
                        })}
							</ul>
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-4 col-md-6 md-mb-30">
					<div className="footer__area-footer-widget">
						<div className="footer__area-title">
							<h3>Information</h3>
						</div>
						<div className="footer__area-contact-info">
							<div className="footer__area-contact-info-item d-inline-flex mb-20">
								<div className="footer__area-contact-info-item-icon">
									<i className="fas fa-phone-alt"></i>
								</div>
								<div className="footer__area-contact-info-item-text">
									<a href="tel:+923001567019">+923001567019</a>
								</div>
							</div>
							<div className="footer__area-contact-info-item d-inline-flex mb-20">
								<div className="footer__area-contact-info-item-icon">
									<i className="fas fa-envelope"></i>
								</div>
								<div className="footer__area-contact-info-item-text">
									<a href="mailto:info@contrivermate.com">info@contrivermate.com</a>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<div className="col-xl-3 col-lg-4 col-md-6">
					<div className="footer__area-footer-widget">
						<div className="footer__area-title">
							<h3>Get In Touch</h3>
						</div>
						<form action="#">
							<input type="email" name="email" placeholder="Email" required="required"/>
							<button type="submit" className="theme-banner-btn">submit Now</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div> 
    </>
  )
}

export default FooterComp
