import PageTitle from '../components/PageTitle/PageTitle'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ServicesList from '../components/ServicesList/ServicesList'

const ServiceDetails = () => {
  const params = useParams()

  const { services } = useSelector(
    (state) => state.services
  );

  const service = services.find((service) => service.service_title.replace(/\s+/g, '-').toLowerCase() === params.name)


  return (
    <>
      <PageTitle title="Sevices Details" />

      <div className="services__details section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 lg-mb-30">
              <ServicesList />
            </div>
            <div className="col-xl-8 col-lg-8">
              <div className="services__details-right-sidebar">

                <div className="services__details-content">
                  <h4 className="mb-25">{service && service.service_title}</h4>
                  <p dangerouslySetInnerHTML={{__html: service && service.service_detail}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServiceDetails
