import React from 'react'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import AboutImage from '../../assets/img/about.jpg'
import aboutImage1 from '../../assets/img/icon/services-4.png'
import aboutImage2 from '../../assets/img/icon/services-2.png'
import aboutImage3 from '../../assets/img/icon/services-5.png'

const AboutComp = () => {
  return (
    <>
     <div className="about__area section-padding">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-xl-6 col-lg-6 lg-mb-30">
					<div className="about__area-left">
						<div className="about__area-left-content">
							<h6><CountUp end={3} enableScrollSpy className="counter"/>+</h6>
							<p>Years Experience</p>
						</div>
						<div className="about__area-left-image">
							<img src={AboutImage} alt=""/>
						</div>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6">
					<div className="about__area-right">
						<div className="about__area-right-title">
							<p className="section-top">About Company</p>
							<h2>Making IT a part of your business</h2>
							<p>We've been in business for over three years, and we're excited to see what the future holds for us. Our philosophy is simple: we want to make your website look great, sound great, and work exactly how you need it to.</p>
						</div>
						<div className="about__area-right-list">
							<div className="about__area-right-list-item">
								<div className="about__area-right-list-item-icon">
									<img src={aboutImage1} alt=""/>
								</div>
								<div className="about__area-right-list-item-text">
									<h4>Business improvement</h4>
								</div>
							</div>
							<div className="about__area-right-list-item center">
								<div className="about__area-right-list-item-icon">
									<img src={aboutImage2} alt=""/>
								</div>
								<div className="about__area-right-list-item-text">
									<h4>Network Administration</h4>
								</div>
							</div>
							<div className="about__area-right-list-item">
								<div className="about__area-right-list-item-icon">
									<img src={aboutImage3} alt=""/>
								</div>
								<div className="about__area-right-list-item-text">
									<h4>User Experience</h4>
								</div>
							</div>
						</div>
						<div className="about__area-right-contact">
							<div className="about__area-right-contact-item">
								<Link className="theme-btn" to="services">Discover More</Link>								
							</div>	
							<div className="about__area-right-contact-item">
								<div className="about__area-right-contact-item-call">
									<div className="about__area-right-contact-item-call-icon">	
										<i className="flaticon-phone-call"></i>
									</div>
									<div className="about__area-right-contact-item-call-content">
										<span>Phone :</span>
										<p><a href="tel:+923001567019">+923001567019</a></p>
									</div>
								</div>
							</div>						
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 
    </>
  )
}

export default AboutComp
