import axios from '../../api/axios'

export const getServices = () => async (dispatch)=>{
    try {
        dispatch({type: "getServicesRequest"});
        const {data} = await axios.get('/services')

        dispatch({type: "getServicesSuccess", payload: data.data})
    } catch (error) {
        dispatch({type: "getServicesFail", payload: error.response.data})
    }
}