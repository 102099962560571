import React from 'react'
import { Link } from 'react-router-dom'

const Banner = () => {
  return (
    <div className="banner__area">
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="banner__area-title">
						<span>We Provide the best services</span>
						<h2>We solve IT problems with technology</h2>
						<Link className="theme-btn" to="contact-us">Free Consulting</Link>
					</div>
				</div>
			</div>
		</div>
	</div>
  )
}

export default Banner