import React from 'react'
import ServiceCard from '../ServiceCard/ServiceCard'
import { useSelector } from 'react-redux'

const ServicesSection = () => {


	const {services} = useSelector(
		(state) => state.services
	  );



	return (
		<>
			<div className="services__area section-padding">
				<div className="container">
					<div className="row">
						<div className="col-xl-12 col-lg-12">
							<div className="services__area-title section-title">
								<p className="section-top">Best Services</p>
								<h2>Continuously we offer the best Services for progress!</h2>
							</div>
						</div>
					</div>
					<div className="row">
						{
							services && services.map(({service_title, service_detail},ind)=>{
								return <ServiceCard key={ind} name={service_title} detail={service_detail}/>
							})
						}
						
					</div>
				</div>
			</div>
		</>
	)
}

export default ServicesSection
