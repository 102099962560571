import React from 'react'
import PageTitle from '../components/PageTitle/PageTitle'
import ServicesSection from '../components/ServicesSection/ServicesSection'

const Services = () => {
  return (
    <>
      <PageTitle title="Services"/>
      <ServicesSection/>
    </>
  )
}

export default Services
