import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ServicesList = () => {


    const { services } = useSelector(
        (state) => state.services
    );

    const service = services.map((val) => val.service_title)

    return (
        <>
            <div className="services__details-left-sidebar">
                <div className="services__details-widget-category mb-30">
                    <h3 className="mb-20">Category</h3>
                    <ul>
                        {service.map((val, ind) => {
                            return <li key={ind}><Link to={`/service/${val.replace(/\s+/g, '-').toLowerCase()}`}>{val} <i className="fas fa-chevron-right"></i></Link></li>
                        })}

                    </ul>
                </div>
            </div>
        </>
    )
}

export default ServicesList
