import { createReducer } from "@reduxjs/toolkit";

const serviceReducer = createReducer(
    { services: []},
    {
        getServicesRequest: (state)=> {
            state.loading = true
        },
        getServicesSuccess: (state, action)=> {
            state.loading = false;
            state.services = action.payload
        },
        getServicesFail: (state, action)=> {
            state.loading = false;
            state.error = action.payload
        },
    }
)

export default serviceReducer