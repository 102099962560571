import React from 'react'
import AboutComp from '../components/AboutCom/AboutComp'
import CounterSection from '../components/CounterSection/CounterSection'
import DevelopmentArea from '../components/DevelopmentArea/DevelopmentArea'
import FaqArea from '../components/Faq/FaqArea'
import PageTitle from '../components/PageTitle/PageTitle'
import SkillSection from '../components/SkillSection/SkillSection'

const About = () => {
  return (
    <>
     <PageTitle title="About"/> 
     <AboutComp/>
     <CounterSection/>
     <SkillSection/>
     <DevelopmentArea/>
     <FaqArea/>
    </>
  )
}

export default About
