import React from 'react'
import CountUp from 'react-countup';

const CounterSection = () => {
    return (
        <>
            <div className="counter__area section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 lg-mb-30">
                            <div className="counter__area-item">
                                <ul className="d-inline-flex align-items-center t-left">
                                    <li><i className="flaticon-project-plan"></i>
                                    </li>
                                    <li>
                                        <h2><CountUp end={50} enableScrollSpy className="counter"/>+</h2>
                                        <p>Completed Project</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 sm-mb-30">
                            <div className="counter__area-item">
                                <ul className="d-inline-flex align-items-center t-left">
                                    <li><i className="flaticon-badge"></i>
                                    </li>
                                    <li>
                                        <h2><CountUp end={10} enableScrollSpy className="counter"/>+</h2>
                                        <p>Team Members</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6">
                            <div className="counter__area-item">
                                <ul className="d-inline-flex align-items-center t-left">
                                    <li><i className="flaticon-team"></i>
                                    </li>
                                    <li>
                                        <h2><CountUp end={50} enableScrollSpy className="counter"/>+</h2>
                                        <p>Happy Client</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CounterSection
