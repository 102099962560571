import React from 'react'

const WhyChooseUs = () => {
  return (
    <>
     <div className="choose__ua section-padding">
		<div className="choose__ua-left"></div>
		<div className="container">
			<div className="row">
				<div className="col-xl-6 col-lg-6"></div>
				<div className="col-xl-6 col-lg-6">
					<div className="s-table">
						<div className="s-tablec">
							<div className="choose__ua-right">
								<p className="section-top">Why Choose Us</p>
								<h2 className="mb-35">Examples of information technology</h2>
								<div className="choose__ua-right-list">
									<div className="row">
										<div className="col-sm-6 mb-30">
											<div className="choose__ua-right-list-icon">
												<i className="flaticon-technical-support"></i>
												<h4>IT Consulting<br/>Management</h4>
											</div>
										</div>
										<div className="col-sm-6 sm-mb-30">
											<div className="choose__ua-right-list-icon">
												<i className="flaticon-customer-service"></i>
												<h4>Technology Support<br/>Management</h4>
											</div>
										</div>
										<div className="col-sm-6 sm-mb-30">
											<div className="choose__ua-right-list-icon">
												<i className="flaticon-chip"></i>
												<h4>It Programmer<br/>Analyst</h4>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="choose__ua-right-list-icon">
												<i className="flaticon-networking"></i>
												<h4>Network<br/>Specialist</h4>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	 
    </>
  )
}

export default WhyChooseUs
