import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SkillSection = () => {

    const { services } = useSelector(
        (state) => state.services
    );

    return (
        <>
            <div className="skill__area section-padding pb-0">
                <div className="container">
                    <div className="row align-items-center p-relative">
                        <div className="col-xl-8 col-lg-8 col-md-9 lg-mb-40">
                            <div className="skill__area-title">
                                <h2 className="mb-35">Skill Standards for Information Technology.</h2>
                                <Link className="theme-banner-btn" to="/services">Discover More</Link>
                            </div>
                        </div>
                    </div>
                    <div className="skilbar__area-box p-relative">
                        <div className="row">
                            {
                                services.map((val, ind) => {
                                    return <div key={ind} className='col-xl-4 col-lg-4 col-md-4'>
                                        <div className='skilbar__area-item mb-30'>
                                            <div className="skilbar__area-content">
                                                <h5 className='t-center'>{val.service_title}</h5>
                                            </div>
                                            <div className='t-center'>
                                                
                                            </div>
                                        </div>
                                    </div>
                                })
                            }

                            {/* <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="skilbar__area-item mb-30">
                                    <div className="skilbar__area-content">
                                        <h5>Software development</h5>
                                        <span className="skilbar__area-count">
                                            <span className="counter">75</span>%</span>
                                    </div>
                                    <div className="skilbar__area-inner">
                                        <div className="skilbar__area-bar" data-width="75"></div>
                                    </div>
                                </div>
                                <div className="skilbar__area-item md-mb-30">
                                    <div className="skilbar__area-content">
                                        <h5>Web Development</h5>
                                        <span className="skilbar__area-count">
                                            <span className="counter">85</span>%</span>
                                    </div>
                                    <div className="skilbar__area-inner">
                                        <div className="skilbar__area-bar" data-width="85"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6">
                                <div className="skilbar__area-item mb-30">
                                    <div className="skilbar__area-content">
                                        <h5>Cyber Security</h5>
                                        <span className="skilbar__area-count">
                                            <span className="counter">55</span>%</span>
                                    </div>
                                    <div className="skilbar__area-inner">
                                        <div className="skilbar__area-bar" data-width="55"></div>
                                    </div>
                                </div>
                                <div className="skilbar__area-item">
                                    <div className="skilbar__area-content">
                                        <h5>Data Recovery</h5>
                                        <span className="skilbar__area-count">
                                            <span className="counter">90</span>%</span>
                                    </div>
                                    <div className="skilbar__area-inner">
                                        <div className="skilbar__area-bar" data-width="90"></div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SkillSection
