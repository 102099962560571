import React from 'react'

const TopBar = () => {
  return (
    // <div className='top'>
    //   <div className='custom-container'>

    //   </div>
    // </div>

    <div className="header__area-topbar-area">
      <div className="header__area-topbar custom-container">
        <div className="header__area-topbar-left">
          <div className="header__area-topbar-left-info md-mb-10">
            <ul>
              <li><a href="mailto:info@contrivermate.com"><i className="flaticon-email"></i>info@contrivermate.com</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="header__area-topbar-right">
          <div className="header__area-topbar-right-social">
            <ul>
              <li><a href="https://www.facebook.com/Contrivermate" target="blank"><i className="fab fa-facebook-f"></i></a>
              </li>
              <li><a href="https://www.instagram.com/contrivermate786/" target="blank"><i className="fab fa-instagram"></i></a>
              </li>
              <li><a href="./#"><i className="fab fa-linkedin-in"></i></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopBar
