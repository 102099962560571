import React from 'react'

const ContactForm = () => {
  return (
    <>
     <div className="contact__form">
		<div className="container">
			<div className="row">
				<div className="col-xl-12">
					<div className="contact__form t-center">
						<div className="contact__form-form">
							<div className="contact__form-form-title">	<span className="section-top">Get in Touch</span>
								<h2>Always ready for your solutions</h2>
							</div>
							<form action="#">
								<div className="row">
									<div className="col-lg-4 col-md-6 mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-user"></i>
											<input type="text" name="name" placeholder="Your Name" required="required"/>
										</div>
									</div>
									<div className="col-lg-4 col-md-6 md-mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-envelope-open"></i>
											<input type="text" name="email" placeholder="Your Email" required="required"/>
										</div>
									</div>
									<div className="col-lg-4 lg-mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-address-book"></i>
											<input type="text" name="subject" placeholder="Subject" required="required"/>
										</div>
									</div>
									<div className="col-lg-12 mb-30">
										<div className="contact__form-form-group icon">	<i className="far fa-comments"></i>
											<textarea name="message" rows="8" placeholder="Message"></textarea>
										</div>
									</div>
									<div className="col-lg-12">
										<div className="contact__form-form-group">
											<button className="theme-btn" type="submit" name="button">Submit Now <i className="flaticon-send"></i>
											</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> 
    </>
  )
}

export default ContactForm
