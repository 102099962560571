import React from 'react'
import { Link } from 'react-router-dom'

const DevelopmentArea = () => {
  return (
    <>
     <div className="development__area section-padding">
		<div className="container">
			<div className="row">
				<div className="col-xl-12 p-relative">
					<div className="development__area-title t-center">
						<h2 className="mb-30">Professional Web Design and Software Development Company</h2>
						<Link className="theme-banner-btn" to="/about">Discover More</Link>
					</div>
				</div>
			</div>
		</div>
	</div> 
    </>
  )
}

export default DevelopmentArea
