import React from 'react'
import { Link } from 'react-router-dom'
import ServiceImage from '../../assets/img/icon/services-6.png'

const ServiceCard = ({name, detail}) => {

    var urlServiceName = name;
    urlServiceName = urlServiceName.replace(/\s+/g, '-').toLowerCase()

    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-5">
                <div className="services__area-item">
                    <div className="services__area-item-icon">
                        <img src={ServiceImage} alt="service_img" />
                    </div>
                    <h3><a href="services-details.html">{name}</a></h3>
                    <p>{detail.slice(0, 100)}...</p> <Link to={`/service/${urlServiceName}`}>Read More</Link>
                </div>
            </div>
        </>
    )
}

export default ServiceCard
