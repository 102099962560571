import React from 'react'
import { Link } from 'react-router-dom'

const CopyRight = () => {
  return (
    <>
     <div className="footer__area-bottom">
		<div className="container">
			<div className="row align-items-center pt-40 pb-40">
				<div className="col-xl-6 col-lg-6 col-md-7 md-mb-30">
					<div className="footer__area-bottom-left">
						<p>Copyright © 2022 - <Link to="/">Contrivermate</Link> All Right Reserved</p>
					</div>
				</div>
				<div className="col-xl-6 col-lg-6 col-md-5">
					<div className="footer__area-bottom-right t-right">
						<ul className="d-inline-flex">
							<li><a href="https://www.facebook.com/Contrivermate" target="blank"><i className="fab fa-facebook-f"></i></a>
							</li>
							<li><a href="https://www.instagram.com/contrivermate786/" target="blank"><i className="fab fa-instagram"></i></a>
							</li>
							<li><a href="/#"><i className="fab fa-linkedin-in mr-0"></i></a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div> 
    </>
  )
}

export default CopyRight
