import React from 'react'
import ContactDetailCard from '../components/ContactDetailCard/ContactDetailCard'
import ContactForm from '../components/ContactForm/ContactForm'
import PageTitle from '../components/PageTitle/PageTitle'

const ContactUs = () => {
  return (
    <>
      <PageTitle title="Contact Us"/>
      <ContactDetailCard/>
      <ContactForm/>
    </>
  )
}

export default ContactUs
